* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

@import "./variables.scss";

.wrapper {
  max-width: 1024px;
  margin: 0 auto;
  width: calc(100% - 48px);
}

body {
  background-size: 64px;
}

.button {
  background: linear-gradient(45deg, #0C4A7C, #021840);
  background-color: $mainColor;
  font-weight: 600;
  padding: 12px 24px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  color: white;

  &:hover {
    background-color: darken($mainColor, 15%);
  }
}

.sectionTitle {
  font-size: 48px;
  margin-bottom: 25px;
  text-align: center;
}

.section {
  padding: 50px 0;

  .sectionTitle {
    color: #3b3b3b;

    &:after {
      background-color: #222;
    }
  }

  p {
    margin-bottom: 16px;
    font-size: 18px;
    line-height: 1.5;
    color: #4D4D4D;
  }

  &.dark {
    .sectionTitle {
      color: #fff;

      &:after {
        background-color: #fff;
      }
    }

    background-size: 64px;
    background: linear-gradient(45deg, #0C4A7C, #021840);

    p {
      color: #fff;
    }
  }
}

.work {
  width: 20px;

}

@media screen and (max-width: 800px) {
  .section {
    padding: 80px 0;
  }
}

@media screen and (max-width: 600px) {
  .section {
    padding: 36px 0;

    p {
      font-size: 16px;
    }
  }

  .sectionTitle {
    font-size: 28px;
    margin-bottom: 32px;
  }
}