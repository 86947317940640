@import "../../variables.scss";

.hero {
  padding-top: 120px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  padding-bottom: 20px;
}

.heroMask {
  background-color: #111;
  opacity: 0.6;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1;
}

.heroUpperTitle {
  color: lighten($mainColor, 90%);
  opacity: 0.7;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 24px;
  text-align: center;
}

.heroTitle {
  color: #fff;
  font-size: 56px;
  max-width: 750px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 45px;
}

.wrapper {
  display: flex;
  align-items: center;
  height: 450px;
  z-index: 2;
  position: relative;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.graphic {
  height: 320px;
}

.button {
  margin-top: 48px;
}

@media screen and (max-width: 960px) {
  .heroUpperTitle {
    font-size: 16px;
  }

  .heroTitle {
    font-size: 40px;
  }

  .graphic {
    height: 260px;
  }
}

@media screen and (max-width: 820px) {
  .graphicContainer {
    display: none;
  }

  .heroUpperTitle {
    font-size: 20px;
  }

  .heroTitle {
    font-size: 60px;
    max-width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .heroUpperTitle {
    font-size: 16px;
  }

  .heroTitle {
    font-size: 40px;
  }

  .wrapper {
    height: 540px;
  }
}

@media screen and (max-width: 380px) {
  .heroTitle {
    font-size: 36px;
  }

  .button {
    margin-top: 32px;
  }

  .wrapper {
    height: 480px;
  }

  .hero {
    padding-top: 72px;
  }
}