.container {
    max-width: 1000px;
    margin: 0 auto;
    padding-bottom: 80px;
    text-align: center;
    font-family: Arial, sans-serif;

}

.header {
    margin-bottom: 30px;
}

.header h1 {
    font-size: 2.8rem;
    margin-bottom: 20px;
    padding-top: 50px;
    color: #3b3b3b;
}

.header p {
    font-size: 1rem;
    color: #555;
}

.columns {
    display: flex;
    justify-content: space-between;
    gap: 50px;
    flex-direction: row;
    align-items: flex-start;
    /* Alinea las columnas en la parte superior */
}

.column {
    flex: 1;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    text-align: center;
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    /* Asegura que los elementos dentro de cada columna se apilen */
    align-items: center;
}

.column:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.icon {
    font-size: 2rem;
    margin-bottom: 10px;
    max-height: 100px;
    max-width: fit-content;
}

.column h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: #3b3b3b;
}

.column p {
    font-size: 1rem;
    color: #666;
}

/* Estilos Responsivos */
@media (max-width: 768px) {
    .columns {
        flex-direction: column;
        /* Cambia las columnas a filas en pantallas pequeñas */
        gap: 15px;
    }

    .column {
        margin-bottom: 15px;
        /* Añade espacio entre las filas */
    }
}

@media (max-width: 480px) {
    .header h1 {
        font-size: 1.5rem;
        /* Reduce el tamaño del título */
    }

    .header p {
        font-size: 0.9rem;
        /* Reduce el tamaño de la descripción */
    }

    .icon {
        width: 50px;
        /* Ajusta el tamaño del icono */
        height: 50px;
    }

    .column h3 {
        font-size: 1rem;
        /* Reduce el tamaño de los títulos de las columnas */
    }

    .column p {
        font-size: 0.9rem;
        /* Reduce el tamaño del texto */
    }
}