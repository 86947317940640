@import "../../variables.scss";

.header {
  position: fixed;
  width: 100%;
  z-index: 10000;
  transition: all 0.2s;
  background-color: white;

  &.small {
    background-color: white;
    box-shadow: 0 3px 5px 0 rgba(#000, 0.05);
  }

  &.small .wrapper {
    height: 70px;
  }
}

.logo {
  border: none;
  background: transparent;
  cursor: pointer;
}

.logoImg {
  height: 25px;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  transition: all 0.2s;
  background-color: white;
}

.nav {
  ul {
    display: flex;
    align-items: center;
  }

  li {
    list-style: none;

    button {
      padding: 0 18px;
      font-weight: 600;
      cursor: pointer;
      display: block;
      transition: all 0.2s;
      background: transparent;
      border: none;
      color: #4D4D4D;
      font-size: 16px;
      font-style: inter;

      &:hover {
        transform: translate3d(0, 2px, 0);
      }

      &.navHighlight {
        color: white;
        background: linear-gradient(45deg, #0C4A7C, #021840);
        padding: 10px 16px;
        border-radius: 10px;
        margin-left: 10px;
      }
    }
  }
  
}

.pancake {
  display: none;
  align-items: center;
  background: transparent;
  cursor: pointer;
  border: none;
  transition: all 0.2s;
  z-index: 1001;

  @media screen and (max-width: 860px) {
    display: flex;
    position: relative;
  }

  >span {
    font-weight: 600;
    font-size: 14px;
    color: #000;
  }

  &.active {
    >span {
      color: #021840;
    }

    .pancakeBox span {
      background-color: #021840;

      &:nth-child(1) {
        transform: translate3d(0, 10px, 0) rotate(45deg);
      }

      &:nth-child(2) {
        opacity: 0;
        transform: scaleY(0);
      }

      &:nth-child(3) {
        transform: translate3d(0, -10px, 0) rotate(-45deg);
      }
    }
  }

  &:active {
    transform: scale(0.9);
  }

  &:not(.active):hover {
    .pancakeBox span:nth-child(1) {
      transform: translate3d(0, 2px, 0);
    }

    .pancakeBox span:nth-child(3) {
      transform: translate3d(0, -2px, 0);
    }
  }
}

.pancakeBox {
  width: 36px;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 12px;

  span {
    display: block;
    width: 100%;
    height: 3px;
    background-color: #000;
    margin: 3px 0;
    border-radius: 10px;
    transition: all 0.2s;
  }
}

@media screen and (max-width: 860px) {
  .pancake {
    display: flex;
  }

  .nav {
    position: absolute;
    top: 100px;
    left: 20px;
    background-color: white;
    width: calc(100% - 40px);
    padding: 20px;
    border-radius: 10px;
    transform: translate3d(0, 50px, 0);
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s;
    box-shadow: 0 10px 20px 0 rgba(#000, 0.1);
    

    &.active {
      transform: translate3d(0, 0, 0);
      opacity: 1;
      visibility: visible;
    }

    ul {
      display: block;
      text-align: center;
    }

    li {
      button {
        color: #021840;
        width: 100%;
        padding: 10px 0;
        margin: 5px 0;

        &.navHighlight {
          color: white;
          background: linear-gradient(45deg, #0C4A7C, #021840);
          width: 180px;
          margin: 10px auto 14px;
        }

        &:hover {
          color: #0C4A7C;
        }
      }
    }
  }
}