.us {
  position: relative;
}

.body {
  text-align: justify;
  color: black;
  font-family: "Inter", sans-serif;
  text-align: center;
  color: #4D4D4D;
  margin-bottom: 50px;
  font-size: 18px;
}

.Nosotros {
  font-size: 48px;
  margin-bottom: 25px;
  color: #3b3b3b;
  font-family: "Inter", sans-serif;
  justify-content: center;
  text-align: center;
}