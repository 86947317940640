.form {
  width: 50%;

  label {
    font-size: 14px;
    font-weight: 600;
    display: block;
    margin-bottom: 4px;
    padding-left: 2px;
    color: #888;

    span {
      color: #bbb;
    }
  }

  input,
  textarea {
    width: 100%;
    border: none;
    border: 2px solid #888;
    border-radius: 10px;
    font-weight: 600;
    padding: 10px;
    margin-bottom: 20px;
    outline: 0;

    &:focus {
      border: 2px solid #04036d;
    }
  }

  button[disabled] {
    opacity: 0.4;
    pointer-events: none;
  }

  textarea {
    height: 120px;
  }
}

.form .formItem:focus-within {
  label {
    color: #04036d;
  }
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 48px;
}

.map {
  width: 50%;
  display: flex;
  justify-content: flex-end;

  .mapContainer {
    width: 100%;

    iframe {
      width: 100%;
      margin-top: -28px;
      border-radius: 10px;
    }
  }
}

@media screen and (max-width: 920px) {}

@media screen and (max-width: 740px) {
  .container {
    display: block;
  }

  .map,
  .form {
    width: 100%;
  }

  .map .mapContainer iframe {
    margin-top: 42px;
  }
}

.lema {
  padding-top: 70px;
  justify-content: center;
  text-align: center;
  font-size: 48px !important;
  font-family: "Inter", sans-serif !important;
  font-weight: 800;
  // color: linear-gradient(45deg, #0C4A7C, #021840) !important;
  background: linear-gradient(to right, #0C4A7C, #021840) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}