.currentClient {
  h3 {
    color: #fff;
    font-size: 32px;
    margin-bottom: 24px;
  }

  p {
    margin-bottom: 48px;
  }
}

.clientList {
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  button {
    width: 100%;
    border: none;
    background: transparent;
    padding: 48px;
    opacity: 0.3;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }

    &.active {
      opacity: 1;
    }

    img {
      width: 100%;
    }
  }
}

.slider {
  margin-top: 64px;
  max-width: 100%;
  max-height: 5%;
  background: transparent;
}

@media screen and (max-width: 800px) {
  .clientList {
    button {
      padding: 30px;
    }
  }
}

@media screen and (max-width: 680px) {
  .clientList {
    button {
      padding: 18px;
    }

    .slider {
      margin-top: 32px;
    }

    .currentClient {
      h3 {
        font-size: 28px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .clientList {
    grid-template-columns: repeat(2, 1fr);

    button {
      padding: 10px 48px;
    }
  }
}

@media screen and (max-width: 380px) {
  .clientList {
    button {
      padding: 2px 32px;
    }
  }
}

.body {
  text-align: justify;
}

.clientSlide {
  width: 100%;

  img {
    width: 50%;
    margin: 0 auto;
  }
}

.image {
  height: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  /* Sombra leve */
}

.mainContainer {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
}

.imageContainer {
  flex: 1;
  max-width: 30%;
  display: flex;
  justify-content: center;
  /* Centra la imagen horizontalmente */
  align-items: center;
  /* Centra la imagen verticalmente */
}

.image {
  max-width: 350px;
  /* Limita el ancho máximo a 300px */
  max-height: 260px;
  width: 100%;
  /* La imagen no debe superar el 100% de su contenedor */
  border-radius: 8px;
  /* Añade un borde redondeado */
  object-fit: cover;
  /* Asegura que la imagen se ajuste bien dentro de su contenedor */
}

.textContainer {
  flex: 2;
  max-width: 65%;
  /* Ajusta la columna del texto y slider */
}

.sliderContainer {
  margin-top: 20px;
}

.sectionTitle {
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .mainContainer {
    flex-direction: column;
    text-align: center;
  }

  .imageContainer {
    max-width: 50%;
    margin: 0 auto;
  }

  .textContainer {
    max-width: 100%;
    padding-left: 35px;
    padding-right: 35px;
  }
}

@media screen and (max-width: 600px) {
  .mainContainer {
    gap: 10px;
  }
}