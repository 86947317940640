.service {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 80px 0;

  &.right {
    flex-direction: row-reverse;

    .info {
      text-align: right;
    }
  }

  &+.service {
    margin-top: 124px;
  }
}

.info {
  width: 50%;

  h3 {
    margin-bottom: 18px;
    font-size: 24px;
  }

  p {
    margin-bottom: 32px;
  }
}

.graphic {
  width: 40%;
}

@media screen and (max-width: 560px) {
  .graphic {
    width: 100%;

    img {
      height: 180px;
      margin-bottom: 40px;
    }
  }

  .info {
    width: 100%;

    h3 {
      margin-bottom: 8px;
    }
  }

  .service {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0 8px 16px 0 rgba(#000, 0.05);
    border: 1px solid rgba(#000, 0.05);
    padding: 48px 24px;

    &+.service {
      margin-top: 64px;
    }

    &.right {
      flex-direction: column-reverse;

      .info {
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 420px) {
  .graphic {
    img {
      max-width: 200px;
      width: 100%;
    }
  }
}

.body {
  text-align: justify;
}

.sectionTitle {
  text-align: center;
}

.text {
  text-align: center;
  max-width: 85%;
  margin: 0 auto;
  font-size: 22px !important; //NICO, tenemos que ver eso porque no quiero dejar la etiqueta important
}

.h2 {
  font-size: 56px;
}

.servicesContainer {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  /* Espacio entre columnas */
  margin-top: 40px;
  flex-wrap: wrap;
  /* Permite que se ajuste en pantallas pequeñas */
  margin-bottom: 100px;
  align-items: center;
  /* Centra horizontalmente */
}

.serviceColumn {
  display: flex;
  /* Activa Flexbox en el contenedor */
  flex-direction: column;
  /* Asegura que los elementos se apilen verticalmente */
  align-items: center;
  /* Centra horizontalmente */
  position: relative;
  /* Mantén esto si necesitas posicionamiento absoluto dentro */
  flex: 1;
  max-width: 30%;
  text-align: center;
}

.serviceImage {
  width: 100%;
  max-height: 280px;
  max-width: 300px;
  object-fit: cover;
  border-radius: 8px;
  background-color: #C9CFCF;
}

.serviceBox {
  position: absolute;
  /* Posiciona el .serviceBox sobre la imagen */
  top: 65%;
  /* El .serviceBox comienza desde el 40% de la altura de la imagen */
  left: 0;
  width: 90%;
  padding: 10px;
  background-color: #F5F7FA;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 5%;
}

.serviceBox h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.serviceBox p {
  font-size: 16px;
  line-height: 1.5;
  color: #555;
}

@media screen and (max-width: 1024px) {
  .serviceColumn {
    max-width: 45%;
    /* Aumenta el tamaño de las columnas en pantallas medianas */
  }
}

@media screen and (max-width: 720px) {
  .servicesContainer {
    justify-content: center;
    /* Centra las columnas */
  }

  .serviceColumn {
    max-width: 90%;
    /* Hace que las columnas ocupen más espacio en pantallas pequeñas */
    margin-bottom: 30px;
    /* Ajusta el margen entre columnas */
  }

  .serviceImage {
    max-width: 100%;
    /* Asegura que las imágenes sean responsivas */
    max-height: 250px;
  }

  .serviceBox {
    position: static;
    /* Deja que las cajas de servicio fluyan con el contenido */
    margin: 0;
    padding: 20px;
    width: 100%;
    box-shadow: none;
    /* Elimina la sombra en pantallas pequeñas para simplificar el diseño */
  }

  .serviceBox h3 {
    font-size: 16px;
  }

  .serviceBox p {
    font-size: 14px;
  }
}