.footer {
  padding: 24px 0 40px;
  background: linear-gradient(45deg, #0c4a7c, #021840);

  .wrapper {
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 860px) {
      align-items: center;
      padding: 0 20px;
    }
  }

  .card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;

    @media screen and (max-width: 860px) {
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
  }
}

.logo {
  background: transparent;
  cursor: pointer;
  border: none;

  img {
    height: 32px;
  }
}

.info {
  margin: 48px 0;
  color: white;

  @media screen and (max-width: 860px) {
    margin: 24px 0;
    text-align: center;
  }

  p {
    margin: 0;
  }
}

.copyright {
  margin: 0 !important;
  font-size: 14px !important;
  opacity: 1;
  color: white;
  padding-top: 20px;
  text-align: center;

  @media screen and (max-width: 860px) {
    text-align: center;
  }
}

@media screen and (max-width: 460px) {
  .logo {
    img {
      height: 32px;
    }
  }
}

.bold {
  font-weight: bold;
  padding-bottom: 5px;
}

.emailContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;

  @media screen and (max-width: 860px) {
    width: 100%;
    justify-content: center;
  }
}

.emailInput {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #021840;
  border-radius: 5px;
  outline: none;
  flex: 1;
  background-color: #364e6e;
  color: white;
}

.emailInput::placeholder {
  color: white;
}

.iconButton {
  padding: 6px;
  background-color: #0f3758;
  border: 2px solid #021840;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;

  &[disabled] {
    opacity: 0.3;
    pointer-events: none;
  }
}

.iconButton:hover {
  background-color: #021840;
}

.buttons {
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: 200;
}

.info2 {
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: 200;
}

//364E6E
